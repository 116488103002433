















































































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { useState, useMutations, useActions } from '@u3u/vue-hooks'

import storage, { User } from '@/composables/storage'
import { enableDashboard, isDashboardEnabled } from '@/composables/ui'

import Help from '@/components/popups/Help.vue'

export default defineComponent({
  name: 'chrome',
  components: { Help },
  props: {},

  setup(props, ctx) {
    const { hasUser, token, data } = useState('user', ['hasUser', 'token', 'data'])
    const { SET_TOKEN } = useMutations('user', ['SET_USER', 'SET_TOKEN'])
    const { LOGOUT } = useActions('user', ['LOGOUT'])
    const localData = ref(storage.data)
    const isHelpPopupVisible = ref(false)
    const key = ref(1)

    const logout = () => {
      storage.reset()
      LOGOUT({ fully: true })

      closeMenu()
      key.value += 1
      ctx.root.$router.push({
        name: 'Home',
      })
    }

    const login = (user: User) => {
      LOGOUT({ fully: true }).then(() => {
        enableDashboard()
        closeMenu()
        SET_TOKEN(user.token)
        ctx.root.$router.push({
          name: 'Auth',
          query: ctx.root.$route.query,
        })
      })
    }

    const isDev = process.env.VUE_APP_RELEASE === 'development'
    const isOpen = ref(false)
    const closeMenu = () => (isOpen.value = false)
    const users = computed(() => {
      const currentUser = {
        firstname: data.value.firstname,
        lastname: data.value.lastname,
        token: token.value,
      }

      // Dashboard is disabled if user had/has ?token=xxx
      let userList = isDashboardEnabled()
        ? localData.value.users.filter(user => !user.loginToken)
        : localData.value.users.filter(user => user.loginToken)

      userList = hasUser.value ? userList.filter(user => user.token !== token.value) : userList

      return {
        currentUser,
        userList,
      }
    })

    const getBadge = (user: User) => {
      if (!user || !user.firstname || !user.lastname) {
        return ''
      }

      return `${user.firstname.split('')[0]}${user.lastname.split('')[0]}`
    }

    const countDownTimerDuration = 1000 * 60 * 30 // 30 min
    let countDownStartTime: number
    let countDownTimer: number
    let countDownInterval: ReturnType<typeof setInterval> | undefined

    const startCountdown = () => {
      countDownStartTime = Date.now()
      countDownTimer = countDownTimerDuration

      return setInterval(() => {
        // clearInterval is not clearing for whatever reason
        if (!countDownInterval) {
          return
        }

        if (navigator.userActivation.isActive) {
          countDownStartTime = Date.now()
          countDownTimer = countDownTimerDuration

          return
        }

        countDownTimer = Date.now() - countDownStartTime
        if (countDownTimer > countDownTimerDuration && hasUser.value) {
          console.log('clear', '///')
          // logout()
        }
      }, 1000)
    }

    watch(hasUser, loggedIn => {
      if (loggedIn) {
        // if (loggedIn && !isDev) {
        countDownInterval = startCountdown()
      } else {
        countDownInterval && clearInterval(countDownInterval)
        countDownInterval = undefined
      }
    })

    return {
      isHelpPopupVisible,
      isDev,
      key,
      closeMenu,
      getBadge,
      isOpen,
      login,
      logout,
      hasUser,
      localData,
      users,
      token,
    }
  },
})
