/* eslint-disable no-param-reassign */

import { Store, Payload } from 'vuex'
import { computed, Ref, SetupContext } from '@vue/composition-api'

import { GenericObject, RootState } from '@/inc/types'
import { isObject, logger } from '@/inc/utils'

/**
 * Types
 */
type StoreKeys = string[] | GenericObject
type MapFunction = (ctx: SetupContext, namespace: string, keys: StoreKeys) => GenericObject
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Computed = Readonly<Ref<Readonly<any>>>
type MapStates = { [key: string]: Computed }
type MapMutations = MapStates
type MapGetters = MapStates
type MapActions = MapStates

/**
 * Return a function expect two param contains namespace and map.
 * It will normalize the namespace and then the param's function will handle the new namespace and the map.
 */
function normalizeNamespace(fn: MapFunction) {
  return (ctx: SetupContext, namespace: string | StoreKeys, map?: StoreKeys) => {
    if (typeof namespace !== 'string') {
      map = namespace
      namespace = ''
    } else if (namespace.charAt(namespace.length - 1) !== '/') {
      namespace += '/'
    }

    return fn(ctx, namespace, map as StoreKeys)
  }
}

/**
 * Validate whether given map is valid or not
 */
function isValidMap(map: StoreKeys) {
  return Array.isArray(map) || isObject(map)
}

/**
 * Normalize the map
 * normalizeMap([1, 2]) => [ { key: 1, val: 1 }, { key: 2, val: 2 } ]
 * normalizeMap({a: 1, b: 2}) => [ { key: 'a', val: 1 }, { key: 'b', val: 2 } ]
 */
function normalizeMap(map: StoreKeys) {
  if (!isValidMap(map)) {
    return []
  }

  return Array.isArray(map)
    ? map.map(key => ({ key, val: key }))
    : Object.keys(map).map(key => ({ key, val: map[key] }))
}

/**
 * Search a special module from store by namespace. if module not exist, print error message.
 */
function getModuleByNamespace(store: Store<RootState>, helper: string, namespace: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const module = (store as any)._modulesNamespaceMap[namespace]
  if (process.env.NODE_ENV !== 'production' && !module) {
    logger.error(`[store/utils] module namespace not found in ${helper}(): ${namespace}`)
  }

  return module
}

/**
 * Reduce the code which written in Vue.js for getting the state.
 */
export const mapState = normalizeNamespace((ctx: SetupContext, namespace: string, states: StoreKeys) => {
  const res = {}
  const { $store } = ctx.root

  if (process.env.NODE_ENV !== 'production' && !isValidMap(states)) {
    logger.error('[store/utils] mapState: mapper parameter must be either an Array or an Object')
  }
  normalizeMap(states).forEach(({ key, val }) => {
    res[key] = computed(() => {
      let { getters, state } = $store

      if (namespace) {
        const module = getModuleByNamespace($store, 'mapState', namespace)

        if (!module) {
          return null
        }

        ;({ getters, state } = module.context)
      }

      // eslint-disable-next-line
      // TODO: check this vs ctx for call…
      return typeof val === 'function' ? val.call(ctx, state, getters) : state[val]
    })

    // Mark vuex getter for devtools
    // res[key].vuex = true
  })

  return res as MapStates
})

/**
 * Reduce the code which written in Vue.js for committing the mutation
 */
export const mapMutations = normalizeNamespace((ctx: SetupContext, namespace: string, mutations: StoreKeys) => {
  const res = {}
  const { $store } = ctx.root

  if (process.env.NODE_ENV !== 'production' && !isValidMap(mutations)) {
    logger.error('[store/utils] mapMutations: mapper parameter must be either an Array or an Object')
  }
  normalizeMap(mutations).forEach(({ key, val }) => {
    res[key] = function mappedMutation(...args) {
      // Get the commit method from store
      let { commit } = $store

      if (namespace) {
        const module = getModuleByNamespace($store, 'mapMutations', namespace)

        if (!module) {
          return null
        }

        ;({ commit } = module.context)
      }

      // eslint-disable-next-line
      // TODO: check `this` vs `ctx` for call…
      /* eslint-disable indent */
      return typeof val === 'function'
        ? val.apply(ctx, [commit].concat(args))
        : commit.apply($store, ([val].concat(args) as unknown) as [Payload])
      /* eslint-enable indent */
    }
  })

  return res as MapMutations
})

/**
 * Reduce the code which written in Vue.js for getting the getters
 */
export const mapGetters = normalizeNamespace((ctx: SetupContext, namespace: string, getters: StoreKeys) => {
  const res = {} as GenericObject
  const { $store } = ctx.root

  if (process.env.NODE_ENV !== 'production' && !isValidMap(getters)) {
    logger.error('[store/utils] mapGetters: mapper parameter must be either an Array or an Object')
  }

  normalizeMap(getters).forEach(({ key, val }) => {
    // The namespace has been mutated by normalizeNamespace
    val = namespace + val
    res[key] = computed(() => {
      if (namespace && !getModuleByNamespace($store, 'mapGetters', namespace)) {
        return null
      }

      if (process.env.NODE_ENV !== 'production' && !(val in $store.getters)) {
        logger.error(`[store/utils] unknown getter: ${val}`)

        return null
      }

      return $store.getters[val]
    })

    // Mark vuex getter for devtools
    // res[key].vuex = true
  })

  return res as MapGetters
})

/**
 * Reduce the code which written in Vue.js for dispatch the action
 */
export const mapActions = normalizeNamespace((ctx: SetupContext, namespace: string, actions: StoreKeys) => {
  const res = {}
  const { $store } = ctx.root

  if (process.env.NODE_ENV !== 'production' && !isValidMap(actions)) {
    logger.error('[store/utils] mapActions: mapper parameter must be either an Array or an Object')
  }
  normalizeMap(actions).forEach(({ key, val }) => {
    res[key] = function mappedAction(...args) {
      // Get dispatch function from store
      let { dispatch } = $store

      if (namespace) {
        const module = getModuleByNamespace($store, 'mapActions', namespace)

        if (!module) {
          return null
        }

        ;({ dispatch } = module.context)
      }

      // eslint-disable-next-line
      // TODO: check this vs ctx for call…
      /* eslint-disable indent */
      return typeof val === 'function'
        ? val.apply(this, [dispatch].concat(args))
        : dispatch.apply($store, ([val].concat(args) as unknown) as [Payload])
      /* eslint-enable indent */
    }
  })

  return res as MapMutations
})
