/* eslint-disable @typescript-eslint/no-explicit-any */
import { Module, MutationTree } from 'vuex'
import { RootState } from '@/inc/types'

type UiState = {
  displayWarning: boolean
  profileChanged: boolean
  warningActions: {
    accept: null | (() => any)
    cancel: null | (() => any)
  }
}

const state: UiState = {
  displayWarning: false,
  profileChanged: false,
  warningActions: {
    accept: null,
    cancel: null,
  },
}

const mutations: MutationTree<UiState> = {
  setDisplayWarning: (state, payload) => {
    state.displayWarning = payload
  },

  setProfileChanged: (state, payload) => {
    state.profileChanged = payload
  },
  setWarningActions: (state, payload) => {
    state.warningActions = payload
  },
}

export const ui: Module<UiState, RootState> = {
  namespaced: true,
  state,
  mutations,
}
