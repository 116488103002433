































import { defineComponent, ref } from '@vue/composition-api'
import { useMutations, useState } from '@u3u/vue-hooks'

import Container from './Container.vue'

export default defineComponent({
  name: 'navigate-popup',
  components: { Container },
  props: {},

  setup() {
    const { displayWarning, warningActions } = useState('ui', ['displayWarning', 'warningActions'])
    const { setDisplayWarning } = useMutations('ui', ['setDisplayWarning'])

    const isVisible = ref(displayWarning.value)

    return {
      isVisible,
      warningActions,
      displayWarning,
      setDisplayWarning,
    }
  },
})
