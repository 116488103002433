import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'

import { langDefault } from '@/inc/app.config'
import fr from '@/inc/i18n/locales/fr.json'
import { AppType, Language } from '@/inc/types'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: langDefault, // Set locale
  fallbackLocale: langDefault,
  messages: { fr }, // Set locale messages
  silentTranslationWarn: true,
})

export default i18n

const loadedLanguages = [langDefault] // Our default language that is preloaded

export function setI18nLanguage(lang: Language, type: AppType) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang === 'fr' ? 'fr-BE' : 'nl-BE'

  if (type === 'client') {
    // Not really needed because of `vue-meta`…
    // however document is only available client side
    document.documentElement.lang = lang
  }

  return lang
}

export async function loadLanguageAsync(lang: Language): Promise<Language> {
  if (loadedLanguages.includes(lang)) {
    return lang
  }

  // If the language hasn't been loaded yet
  const messages = await import(/* webpackChunkName: "lang-[request]" */ `@/inc/i18n/locales/${lang}.json`)

  i18n.setLocaleMessage(lang, messages.default)
  loadedLanguages.push(lang)

  return lang
}
