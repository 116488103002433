











import { defineComponent, onMounted, onUnmounted } from '@vue/composition-api'

export default defineComponent({
  name: 'popup',
  components: {},
  props: {},

  setup(props, ctx) {
    const onKey = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        ctx.emit('close')
      }
    }
    onMounted(() => {
      window.addEventListener('keyup', onKey)
      document.body.classList.add('no-scroll')
    })

    onUnmounted(() => {
      window.removeEventListener('keyup', onKey)
      document.body.classList.remove('no-scroll')
    })

    return {}
  },
})
