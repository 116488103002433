import { getApiUrl } from './app.config'

export const zipcode = `${getApiUrl()}/cities`
export const upload = `${getApiUrl()}/upload`
export const i18n = `${getApiUrl()}/i18n`
export const userProfile = `${getApiUrl()}/user`
export const userGdpr = `${getApiUrl()}/user/gdpr`
export const userPackages = `${getApiUrl()}/packages`
export const userMortgages = `${getApiUrl()}/mortgages`
export const userLoans = `${getApiUrl()}/loans`
