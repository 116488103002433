








































































import { defineComponent, computed } from '@vue/composition-api'
import { FeedbackMessage } from '@/inc/types/components'

export default defineComponent({
  name: 'feedback-message',
  components: {},
  props: {
    content: {
      type: Object as () => FeedbackMessage,
      required: true,
    },
  },

  setup(props) {
    const icon = computed(() => {
      const icons = {
        success: 'check',
        warning: 'alert',
        error: 'alert',
      }

      return `icons-${icons[props.content.type]}`
    })

    return { icon }
  },
})
