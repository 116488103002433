import Vue from 'vue'
import Vuex, { Plugin, Store, StoreOptions } from 'vuex'
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import { RootState } from '@/inc/types'
// Modules
import { version } from './modules/version'
import { resource } from './modules/resource'
import { user } from './modules/user'
import { ui } from './modules/ui'
// End modules

Vue.use(Vuex)

export default function createStore(): Store<RootState> {
  const plugins: Plugin<RootState>[] = []

  const store: StoreOptions<RootState> = {
    modules: {
      resource,
      version,
      // Namespaced
      user,
      ui,
    },
    plugins,
  }

  return new Vuex.Store<RootState>(store)
}
