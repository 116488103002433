/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
import { CURRENT_VERSION, LOCAL_DATA_KEY } from '@/inc/app.config'
import { ref } from '@vue/composition-api'

export interface User {
  firstname: string
  lastname: string
  token: string
  loginToken: string | null
}
interface LocalData {
  currentVersion: string | null
  users: User[]
}

const defaultValues = ref<LocalData>({
  currentVersion: CURRENT_VERSION,
  users: [],
})
// Mocks for SSR
let api = {
  data: ref(defaultValues.value),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  set: (payload: any) => {},
  reset: () => {},
}

if (typeof window !== 'undefined') {
  const localData = localStorage.getItem(LOCAL_DATA_KEY)
  const data = ref<LocalData>(localData ? JSON.parse(localData) : defaultValues.value)

  api = {
    data,
    reset: () => api.set(defaultValues.value),
    set: (payload: LocalData) => {
      data.value = payload
      localStorage.setItem(LOCAL_DATA_KEY, JSON.stringify(payload))
    },
  }
}

export default api
