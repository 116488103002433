



































































import { defineComponent } from '@vue/composition-api'

import Container from './Container.vue'

export default defineComponent({
  name: 'help',
  components: { Container },
  props: {},

  setup() {
    return {}
  },
})
