import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { Languages, ResourceState, RootState, ResourceSingle } from '@/inc/types'

const state: ResourceState = {
  content: null,
  slug: '',
  url: '',
  type: '',
  languages: {} as Languages,
  meta: {},
  template: '',
  i18n: {},
} as ResourceSingle

const getters: GetterTree<ResourceState, RootState> = {
  content: state => state.content,
  i18n: state => state.i18n,
  // NOTE: `children` moved to content
  // children: state => state.children,
  languages: state => state.languages,
  meta: state => state.meta,
  template: state => state.template,
}

const mutations: MutationTree<ResourceState> = {
  SET_RESOURCE(state, payload: ResourceSingle) {
    if (payload?.template) {
      const { content, languages, meta, template, slug, url } = payload

      state.content = content
      // NOTE: `children` moved to content
      // state.children = children
      state.languages = languages
      state.meta = { ...state.meta, ...meta }
      state.template = template
      // state.i18n = i18n
      state.slug = slug
      state.url = url
    }
  },

  SET_I18N(state, payload) {
    state.i18n = payload
  },
}

const actions: ActionTree<ResourceState, RootState> = {}

export const resource: Module<ResourceState, RootState> = {
  state,
  getters,
  mutations,
  actions,
}
