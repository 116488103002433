import VueRouter from 'vue-router'
import { Store } from 'vuex'

import { langAvailable, langDefault } from '@/inc/app.config'
import { loadLanguageAsync, setI18nLanguage } from '@/inc/i18n'
import { fetch, logger } from '@/inc/utils'
import { Language, ResourceSingle, RootState } from '@/inc/types'

export const guards = (router: VueRouter, store: Store<RootState>) => {
  // Log
  router.beforeEach((to, from, next) => {
    logger.trace('[guards] from', from.name, 'to', to.name)
    next()
  })

  router.beforeEach((to, from, next) => {
    const { ui } = store.state

    if (ui.profileChanged) {
      store.commit('ui/setDisplayWarning', true)

      new Promise((resolve, reject) => {
        store.commit('ui/setWarningActions', { accept: resolve, cancel: reject })
      })
        .then(() => {
          store.commit('ui/setWarningActions', { accept: null, cancel: null })
          store.commit('ui/setDisplayWarning', false)
          store.commit('ui/setProfileChanged', false)
          next()
        })
        .catch(() => {
          store.commit('ui/setDisplayWarning', false)
        })

      return
    }

    next()
  })

  // Check if page is private
  router.beforeEach((to, from, next) => {
    try {
      if (to.matched.some(record => record.meta.auth)) {
        const hasUser = store.getters['user/hasUser']

        if (!hasUser) {
          return next({
            name: 'Home',
            query: to.query,
            params: {
              ...to.params,
              redirect: to.name ?? '',
              lang: to.params.lang || 'fr',
            },
          })
        }
      }
    } catch (error) {
      console.error('User guard error: ', error)
    }

    return next()
  })

  // Check if documents sign direct link
  // router.beforeEach((to, from, next) => {
  //   try {
  //     const redirectToDocuments = to.matched.some(
  //       record => record.name === 'DocumentsSign'
  //     )

  //     if (redirectToDocuments && to.query.d === undefined) {
  //       to.query.embedded = '0'

  //       return next({
  //         query: to.query,
  //       })
  //     }

  //     return next()
  //   } catch (error) {
  //     console.error('Documents Sign redirect error: ', error)

  //     return next()
  //   }
  // })

  // Debug mode
  router.beforeEach((to, from, next) => {
    if (router.app?.$debug && (from.query.debug !== undefined || to.query.debug !== undefined)) {
      router.app.$debug = true
    }

    if (to.query.debug === undefined && from.query.debug !== undefined) {
      const query = { ...to.query, debug: from.query.debug }

      next({
        name: to.name as string,
        path: to.path,
        hash: to.hash,
        query,
        params: to.params,
      })
    } else {
      next()
    }
  })

  // Manage languages
  if (langAvailable.length > 1) {
    router.beforeEach(async (to, from, next) => {
      const { lang: langCurrent } = from.params as { lang: Language }
      const lang = to.params ? (to.params.lang as Language) : langDefault

      const langHasChanged = langCurrent !== undefined && langCurrent !== lang
      const langNext = langAvailable.includes(lang) ? lang : langDefault

      await loadLanguageAsync(langNext)
        .then(langLoaded => setI18nLanguage(langLoaded, router.$$type))
        .then(lang => {
          if (langHasChanged) {
            store.commit('user/SET_USER', lang)

            return store.dispatch('fetchChrome', { lang })
          }

          return Promise.resolve()
        })
        .catch(logger.error)

      next()
    })
  }

  // Fetch content
  router.beforeEach(async (to, _from, next) => {
    const isSpaFallback = router.$$type === 'client' && document.body.dataset.rendering === 'SPA'
    // Check if fetch is needed:
    // - on SPA fallback
    // - on second rendering ($resource is defined), client side
    const needFetch = isSpaFallback || (router.$resource !== undefined && router.$$type === 'client')

    if (!needFetch) {
      router.$resource = {} as ResourceSingle

      return next()
    }

    // Fetch page resource
    // resource is added through `router`
    await fetch(to, router, false)

    return next()
  })

  // GTM - Analytics
  // router.beforeEach((_to, _from, next) => {
  //   if (router.$$type === 'client') {
  //     // GTM push here
  //     // window.dataLayer?.push({})
  //   }

  //   next()
  // })
}
