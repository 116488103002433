

























/* eslint-disable */
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { DetectUA } from 'detect-ua'
import Cookies from 'js-cookie'
// import axios from 'axios'

import Chrome from '@/components/Chrome.vue'
import DevGrid from '@/components/DevGrid.vue'
import EnvSwitcher from '@/components/EnvSwitcher.vue'
import Navigate from '@/components/popups/Navigate.vue'

import NotFound from '@/views/NotFound.vue'

import { StorageUtils } from '@/inc/plugins/storage'
import storage from '@/composables/storage'
import { isUnderMaintenance, LOGIN_TOKEN, CURRENT_VERSION } from '@/inc/app.config'
import { disableDashboard, enableDashboard, isDashboardEnabled, isMobile } from './composables/ui'
import callApi from './composables/fetchapi'
import { i18n } from './inc/endpoints'
// Import favicon from '@/inc/custom/favicon'

export default Vue.extend({
  name: 'app',
  components: {
    Chrome,
    DevGrid,
    EnvSwitcher,
    NotFound,
    Navigate,
  },
  data() {
    return {
      isMobile: isMobile(),
      hasError: false,
      isUnderMaintenance,
      body: {} as HTMLElement,
      isDashboardEnabled,
    }
  },
  computed: {
    ...mapGetters(['meta', 'chrome', 'template']),
    ...mapGetters('user', ['userToken']),

    isAuthByPassEnabled() {
      const env = process.env.VUE_APP_RELEASE
      return env === 'staging' || env === 'development'
    },
  },
  methods: {
    ...mapMutations({
      setResource: 'SET_RESOURCE',
      setI18n: 'SET_I18N',
    }),
    ...mapMutations('user', { setUser: 'SET_USER', setToken: 'SET_TOKEN' }),
    ...mapActions('user', { logoutUser: 'LOGOUT' }),

    handleAppVersion() {
      const { data } = storage

      if (data.value.currentVersion !== CURRENT_VERSION) {
        this.logoutUser()
        storage.reset()
      }
    },

    handleToken() {
      const { users } = storage.data.value
      const loginToken = this.$route.query.token ?? null
      const existingUser = users.find(savedUser => savedUser.loginToken === loginToken)

      const byPassToken = this.$route.query.u && this.isAuthByPassEnabled ? this.$route.query.u : null
      const savedUserToken = !this.$route.query.token && !byPassToken ? this.userToken : null
      const userToken = existingUser?.token || byPassToken || savedUserToken

      // Logout last user if there is a token in url and no user found
      if (loginToken && !existingUser) {
        this.logoutUser({ fully: true })
        Cookies.set(LOGIN_TOKEN, loginToken, {
          expires: Infinity,
          domain: `${process.env.VUE_APP_MAIN_DOMAIN}`,
        })

        return
      }

      const isDev = process.env.VUE_APP_RELEASE === 'development'
      if (userToken && (isDev || byPassToken)) {
        this.setToken(userToken)
        this.$router.push({
          name: 'Auth',
          query: this.$route.query,
          params: this.$route.params,
        })
      }
    },

    setDashboardVisibility() {
      if (this.$route.query.token?.length) {
        disableDashboard()

        return
      }

      if (this.$route.query.u?.length && this.isAuthByPassEnabled) {
        enableDashboard()
      }
    },
  },
  // Needed for SSR rendering
  // https://vapperjs.org/data-prefetching.html#data-prefetch
  // BUT does not work with composition-api + defineComponent
  needSerialize: true,
  created() {
    const hasUserData = storage.data.value
    if (!hasUserData) {
      storage.reset()
    }

    this.$logger.trace('[app:created]', this.$$type)

    if (!this.$isServer) {
      this.hasError = document.querySelector('[data-server-rendered].error') !== null
    }

    if (this.hasError) {
      return
    }

    // Pass option to root context…
    // More convenient because $options.$storage could be undefined
    this.$root.$storage = this.$root.$options.$storage as StorageUtils

    // Await this.fetchChrome({ lang: this.$i18n.locale, type: this.$$type })

    // Get API response serialized (thanks to `needSerialize`)
    // Or from router (e.g.: SSR error, SPA Fallback)
    // Init store resource
    if (this.$isServer) {
      this.setResource(this.$root.$options.$resource)
    } else if (!this.$isServer && document.body.dataset.rendering === 'SPA') {
      this.setResource(this.$router.$resource)
    }

    callApi(i18n).then(res => {
      this.setI18n(res.data)
    })
  },
  beforeMount() {
    const device = new DetectUA()
    this.$root.$isMobile = !device.isDesktop

    // Check if token has expired
    // axios.interceptors.response.use(
    //   response => response,
    //   error => {
    //     if (error.response && error.response.status === 403) {
    //       this.$router
    //         .push({
    //           name: 'Home',
    //           query: { expired: '1' },
    //         })
    //         .catch(console.error)
    //       this.logoutUser({ fully: true })
    //       this.setUser(null)
    //     }
    //     // DEV
    //     // else {
    //     //   this.$router.push({
    //     //     name: 'Home',
    //     //   })
    //     // }

    //     return Promise.reject(error)
    //   }
    // )

    this.handleAppVersion()
    this.setDashboardVisibility()
    this.handleToken()
  },
  mounted() {
    this.$logger.trace('[app:mounted]')
    this.body = document.body
    this.$on('scroll-disabled', () => (this.body.style.overflow = 'hidden'))
    this.$on('scroll-enabled', () => this.body.removeAttribute('style'))

    // DEV
    // window.addEventListener('beforeunload', () => this.deleteUser())
  },
  watch: {
    $route() {
      // Update store
      this.setResource(this.$router.$resource)
    },
  },
  head() {
    return {
      // ...this.meta,
      // Link: favicon?.link.concat(this.meta?.link || []),
      // meta: favicon?.meta.concat(this.meta?.meta || []),
    }
  },
})
