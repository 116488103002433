import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'

// Using store here generates errors
import { userToken } from '@/store/modules/user'

export default function callApi(
  endpoint: string,
  config: AxiosRequestConfig = { method: 'get', data: {} }
): Promise<AxiosResponse> {
  const headers = {
    Authorization: `Bearer ${userToken}`,
  }

  if (config.headers) {
    Object.defineProperty(config, 'headers', {
      enumerable: true,
      value: {
        ...config.headers,
        ...headers,
      },
    })
  } else {
    config.headers = headers
  }

  return axios({ url: `${endpoint}`, ...config })
}
